import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { RequireAuth } from 'react-auth-kit'
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import UserEditPage from './pages/UserEditPage';
import NewUserPage from './pages/NewUserPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import Page404 from './pages/Page404';
import TaekiPage from './pages/TaekiPage';
import VerkefniPage from './pages/VerkefniPage';
import VerkefniClosedPage from './pages/VerkefniClosedPage';
import VerkefniViewPage from './pages/VerkefniViewPage';
import TaekiViewPage from './pages/TaekiViewPage';
import DashboardAppPage from './pages/DashboardAppPage';
// eslint-disable-next-line import/no-unresolved
import Tilkynningar from './pages/Tilkynningar';
import DownloadApp from './pages/DownloadApp';
import PDFExport from './pages/PDFExport';
import AccountSettings from './pages/AccountSettings';

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth loginPath={'/login'}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'user/:id', element: <UserEditPage /> },
        { path: 'new-user', element: <NewUserPage /> },
        { path: 'verkefni', element: <VerkefniPage /> },
        { path: 'account', element: <AccountSettings /> },
        { path: 'closed', element: <VerkefniClosedPage /> },
        { path: 'verkefni/:id', element: <VerkefniViewPage /> },
        { path: 'taeki', element: <TaekiPage /> },
        { path: 'taeki/:toolSpecificId', element: <TaekiViewPage /> },
        { path: 'tilkynningar', element: <Tilkynningar /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'app',
      element: <DownloadApp />,
    },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
