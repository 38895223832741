/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Container,
  Typography,
  Divider,
  TextField,
  Alert,
  CircularProgress
} from '@mui/material';

import { useForm } from 'react-hook-form';
import Iconify from '../components/iconify';
import { createEmployee } from '../utils/api';

export default function NewUserPage() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add the loading state

  const resetMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const onSubmit = async (data) => {
    resetMessages();
    setIsLoading(true); // Set loading to true when submitting the form

    const { newFirstName, newLastName, newKennitala, newPassword, newPhone, newEmail } = data;
    const fullName = `${newFirstName} ${newLastName}`;
    const firstName = newFirstName;
    const lastName = newLastName;
    const kennitala = newKennitala;
    const password = newPassword;
    const phone = newPhone;
    const email = newEmail;
    const admin = isAdmin;

    try {
      await createEmployee(firstName, lastName, kennitala, password, phone, email, admin, token, signOut);
      setSuccessMessage('Starfsmanni hefur verið bætt við kerfið!');
      reset();
    } catch (error) {
      setErrorMessage(error.message || 'Villa kom upp! Athugið að allar upplýsingar þurfa að vera réttar. Og kennitala / netfang þarf má ekki vera nú þegar í notkun.');
    } finally {
      setIsLoading(false); // Set loading to false when the operation is complete
    }
  };

  return (
    <>
      <Helmet>
        <title> Nýr Starfsmaður | Þúsund Fjalir ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Nýr Starfsmaður
          </Typography>
        </Stack>
        <Card>
          {successMessage && (
            <Alert severity="success">
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newFirstName', { required: true })}
                  name="newFirstName"
                  label="Fornafn"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newFirstName}
                  helperText={errors.newFirstName && 'Vinsamlegast sláðu inn fornafn'}
                />
                <TextField
                  {...register('newLastName', { required: true })}
                  name="newLastName"
                  label="Eftirnafn"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newLastName}
                  helperText={errors.newLastName && 'Vinsamlegast sláðu inn eftirnafn'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newKennitala', { required: true, maxLength: 10, pattern: /[0-9]{10}/ })}
                  name="newKennitala"
                  label="Kennitala"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newKennitala}
                  helperText={errors.newKennitala && 'Vinsamlegast sláðu inn rétta kennitölu'}
                />
                <TextField
                  {...register('newPassword', { required: true })}
                  name="newPassword"
                  label="Lykilorð"
                  variant="outlined"
                  type="password"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword && 'Vinsamlegast sláðu inn lykilorð'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newPhone', { required: true, maxLength: 7, pattern: /[0-9]{7}/ })}
                  name="newPhone"
                  label="Símanúmer"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newPhone}
                  helperText={errors.newPhone && 'Vinsamlegast sláðu inn símanúmer'}
                />
                <TextField
                  {...register('newEmail', { required: true, pattern: /^\S+@\S+$/i })}
                  name="newEmail"
                  label="Netfang"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoComplete="off"
                />
              </Stack>

              <Divider sx={{ my: 3 }}>Verkstjóri?</Divider>

              <TextField
                {...register('isAdmin')}
                name="isAdmin"
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                  displayEmpty: true,
                  defaultValue: 'false'
                }}
                value={isAdmin}
                onChange={(e) => setIsAdmin(e.target.value === 'true')}
              >
                <MenuItem value="true">Já</MenuItem>
                <MenuItem value="false">Nei</MenuItem>
              </TextField>
              {errors.isAdmin && <span>Þú gleymdir að velja hér!</span>}
            </Stack>
            <br /><br />
            {isLoading ? ( // Render the circular progress bar if loading is true
              <Button
                variant="contained"
                disabled
                startIcon={<CircularProgress size={20} />} // Add the circular progress icon
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Stofna
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Stofna
              </Button>
            )}
          </form>
        </Card>
      </Container>
    </>
  );
}
