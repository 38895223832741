/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { employeeList, deleteEmployee, logoutEmployee, resetPasswordEmployee, updateEmployee } from '../utils/api';

// ----------------------------------------------------------------------

const EMPLOYEE_LIST = [];

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Nafn', alignRight: false },
  { id: 'kennitala', label: 'Kennitala', alignRight: false },
  { id: 'phone', label: 'Símanúmer', alignRight: false },
  { id: 'email', label: 'Netfang', alignRight: false },
  { id: 'isAdmin', label: 'Staða', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [loading, setLoading] = useState(true); // New loading state

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const [employeeListState, setEmployeeListState] = useState([]);

  const [openEditModal, setOpenEditModal] = useState(false);

  const [currentEmployee, setCurrentEmployee] = useState(null);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const navigate = useNavigate();

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const fetchEmployeeList = async () => {
    try {
      const employees = await employeeList(token, signOut);
      setEmployeeListState(employees);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching employee list:', error);
      setLoading(false); // Set loading to false in case of error as well
    }
  };

  useEffect(() => {
    fetchEmployeeList();
  }, []);

  const handleOpenMenu = (event, employeeId) => {
    setOpen(event.currentTarget);
    setSelectedEmployeeId(employeeId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedEmployeeId(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = employeeListState.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleNewUser = (event) => {
    navigate('/dashboard/new-user');
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEdit = (employeeId) => {
    const employee = employeeListState.find((employee) => employee.id === employeeId);
    setCurrentEmployee(employee);
    setOpenEditModal(true);
  };

  const handlePasswordReset = async (employeeId) => {
    setLoading(true);
    setOpen(null);
    setSelectedEmployeeId(null);
    try {
      await logoutEmployee(employeeId, token, signOut);
      await resetPasswordEmployee(employeeId, token, signOut);
      showSnackbar('Lykilorð endurstillt og sent á starfsmann.', 'success');
      setLoading(false);
    } catch (error) {
      console.error('Error resetting pw for employee:', error);
      showSnackbar('Villa kom upp við að endurstilla lykilorð fyrir starfsmann', 'error');
      setLoading(false);
    }
  };

  const handleDelete = async (employeeId) => {
    setOpen(null);
    setSelectedEmployeeId(null);
    try {
      await deleteEmployee(employeeId, token, signOut);
      console.log('Delete employee:', employeeId);
      showSnackbar('Starfsmanni var eytt úr kerfi', 'error');
      // Update the employee list by filtering out the deleted employee
      const updatedEmployeeList = employeeListState.filter((employee) => employee.id !== employeeId);
      setEmployeeListState(updatedEmployeeList);
    } catch (error) {
      console.error('Error deleting employee:', error);
      showSnackbar('Villa kom upp við að eyða starfsmanni', 'error');
    }
  };

  const handleForceLogout = async (employeeId) => {
    setLoading(true);
    try {
      await logoutEmployee(employeeId, token, signOut);
      console.log('Logged out employee:', employeeId);
      showSnackbar('Starfsmaður hefur verið útskráður úr appi.', 'warning');
      setOpen(null);
      setSelectedEmployeeId(null);
      setLoading(false);
    } catch (error) {
      console.error('Error logging out employee:', error);
      showSnackbar('Villa kom upp við að útskrá starfsmanni', 'error');
      setLoading(false);
    }
  };

  const updateSelectedUser = async () => {
    setLoading(true);
    try {
      const { id, name, kennitala, phone, email } = currentEmployee;
      await updateEmployee(id, name, kennitala, phone, email, token, signOut);
      // console.log('Update employee:', currentEmployee);
      showSnackbar('Starfsmaður var uppfærður', 'success');
      setOpenEditModal(false);
      setSelectedEmployeeId(null);
      fetchEmployeeList();
    } catch (error) {
      console.error('Error updating employee:', error);
      showSnackbar('Villa kom upp við að breyta starfsmanni', 'error');
      setLoading(false);
    }
  };

  const filteredUsers = applySortFilter(employeeListState, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employeeListState.length) : 0;

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Starfsmenn | Þúsund Fjalir ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Starfsmenn
          </Typography>
          <Button variant="contained" onClick={handleNewUser} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nýr Starfsmaður
          </Button>
        </Stack>
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: 300 }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={employeeListState.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    displayCheckbox={false}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, kennitala, phone, email, isAdmin } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>

                          <TableCell align="left">{id}</TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{kennitala}</TableCell>

                          <TableCell align="left">{phone}</TableCell>

                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="left">
                            <Label color={isAdmin ? 'warning' : 'success'}>{isAdmin ? 'Verkstjóri' : 'Starfsmaður'}</Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Ekkert fannst
                            </Typography>

                            <Typography variant="body2">
                              Engar niðurstöður fundust fyrir &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Athugaðu hvort það séu stafsetningarvillur.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={employeeListState.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEdit(selectedEmployeeId)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Breyta
        </MenuItem>

        <MenuItem onClick={() => handlePasswordReset(selectedEmployeeId)}>
          <Iconify icon={'eva:email-fill'} sx={{ mr: 2 }} />
          Nýtt lykilorð
        </MenuItem>

        <MenuItem onClick={() => handleDelete(selectedEmployeeId)} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Eyða
        </MenuItem>

        <MenuItem onClick={() => handleForceLogout(selectedEmployeeId)} sx={{ color: 'warning.main' }}>
          <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
          Útskrá
        </MenuItem>
      </Popover>

      <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <DialogTitle>Breyta Starfsmanni</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={currentEmployee?.name || ''}
            onChange={(e) => setCurrentEmployee({ ...currentEmployee, name: e.target.value })}
          />
          <TextField
            margin="dense"
            id="kennitala"
            label="Kennitala"
            type="text"
            fullWidth
            value={currentEmployee?.kennitala || ''}
            onChange={(e) => setCurrentEmployee({ ...currentEmployee, kennitala: e.target.value })}
          />
          <TextField
            margin="dense"
            id="netfang"
            label="Netfang"
            type="text"
            fullWidth
            value={currentEmployee?.email || ''}
            onChange={(e) => setCurrentEmployee({ ...currentEmployee, netfang: e.target.value })}
          />
          <TextField
            margin="dense"
            id="phone"
            label="Símanúmer"
            type="text"
            fullWidth
            value={currentEmployee?.phone || ''}
            onChange={(e) => setCurrentEmployee({ ...currentEmployee, phone: e.target.value })}
          />
          {/* Repeat the TextField component for the other fields */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditModal(false)}>Hætta</Button>
          <Button onClick={() => updateSelectedUser()}>Uppfæra</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
