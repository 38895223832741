import { useState, useEffect, Suspense, lazy, React } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuthUser, useSignOut } from 'react-auth-kit'
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card } from '@mui/material';

// sections
import {
  AppWidgetSummary
} from '../sections/@dashboard/app';
import { getStatistics } from '../utils/api';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const fullName = localStorage.getItem('fullName');
  const [openProjects, setOpenProjects] = useState(0);
  const [closedProjects, setClosedProjects] = useState(0);
  const [activeRents, setActiveRents] = useState(0);
  const [totalTools, setTotalTools] = useState(0);
  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  useEffect(() => {
    const getStatisticsInternal = async () => {
      try {
        const stats = await getStatistics(token, signOut);
        setOpenProjects(stats.openProjects);
        setClosedProjects(stats.closedProjects);
        setTotalTools(stats.totalTools);
        setActiveRents(stats.activeRents);

        console.log('Stats:', stats.openProjects, stats.closedProjects, stats.totalTools, stats.activeRents)
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    getStatisticsInternal();
  }, []);

  return (
    <>
      <Helmet>
        <title> Stjórnborð | Þúsund Fjalir ehf </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hæ, velkomin/nn aftur {fullName}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary link="/dashboard/verkefni" title="Opin verk" total={openProjects ?? '0'} icon={'ant-design:project-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary link="/dashboard/taeki" title="Tæki inni" total={totalTools ?? '0'} color="info" icon={'ant-design:database-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary link="/dashboard/closed" title="Lokuð verk" total={closedProjects ?? '0'} color="warning" icon={'ant-design:lock-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary link="/dashboard/verkefni" title="Tæki úti" total={activeRents ?? '0'} color="error" icon={'ant-design:heart-filled'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
