import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Grid } from '@mui/material';
// hooks
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function DownloadApp() {

  return (
    <>
      <Helmet>
        <title> App | Þúsund Fjalir ehf </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sækja App
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <a href="https://play.google.com/store/apps/details?id=is.vatnstjon.thusundapp"><img src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" alt="Android" /></a>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <a href="https://apps.apple.com/us/app/%C3%BE%C3%BAsund-fjalir/id6466995525"><img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="Apple" /></a>
              </Grid>
            </Grid>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
