/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  CircularProgress,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  Box,
  TableContainer,
  TextField,
  TablePagination,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { projectList, createProject } from '../utils/api';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'projectId', label: '#', alignRight: false },
  { id: 'customerAddress', label: 'Heimilisfang', alignRight: false },
  { id: 'date', label: 'Dagsetning', alignRight: false },
  { id: 'view', label: 'Skoða', alignRight: true }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.customerAddress.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function VerkefniPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('projectId');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [heimilisfang, setHeimilisfang] = useState('');
  const [vidskiptavinur, setVidskiptavinur] = useState('');
  const [simanumer, setSimanumer] = useState('');
  const [athugasemdir, setAthugasemdir] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token, id: employeeId } = auth();

  const navigate = useNavigate();

  const [projectListData, setProjectListData] = useState([]);

  useEffect(() => {
    const fetchProjectList = async () => {
      setLoading(true); // Set loading to true at the beginning of data fetching
      try {
        const projects = await projectList(token, signOut);
        setProjectListData(projects); // Update the state variable
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching project list:', error);
        setLoading(false); // Set loading to false in case of error as well
      }
    };

    fetchProjectList();
  }, [refresh]); // Refresh state as dependency to trigger a new network request

  // const handleOpenMenu = (event) => {
  //   setOpen(event.currentTarget);
  // };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleViewProject = (projectId) => {
    navigate(`/dashboard/verkefni/${projectId}`);
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleStofnaClick = async () => {
    // Create a normal date out of the dayjs date, not unix
    setIsLoadingButton(true);
    const unixDate = selectedDate.toDate();
    await createProject(heimilisfang, simanumer, vidskiptavinur, unixDate, athugasemdir, employeeId, token, signOut);
    setIsLoadingButton(false);
    setOpenDialog(false);
    setRefresh(!refresh);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projectListData.length) : 0;

  const filteredUsers = applySortFilter(projectListData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Helmet>
        <title> Verkefni | Þúsund Fjalir ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Verkefni
          </Typography>
          <Button variant="contained" onClick={handleOpenDialog} startIcon={<Iconify icon="eva:plus-fill" />}>
            Stofna Verkefni
          </Button>
        </Stack>
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: 300 }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={projectListData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    displayCheckbox={false}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { projectId, customerAddress, date } = row;
                      const selectedUser = selected.indexOf(customerAddress) !== -1;

                      return (
                        <TableRow
                          hover
                          key={projectId}
                          tabIndex={-1}
                          role="checkbox"
                          onClick={() => handleViewProject(projectId)}
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <TableCell align="left">{projectId}</TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {customerAddress}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography noWrap>
                                {formatDate(date)}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <Button variant="contained" type="submit" onClick={() => handleViewProject(projectId)} startIcon={<Iconify icon="eva:eye-fill" />}>Skoða</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Ekkert fannst
                            </Typography>

                            <Typography variant="body2">
                              Engar niðurstöður fundust fyrir &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Athugaðu hvort það séu stafsetningarvillur.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={projectListData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Breyta
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Eyða
        </MenuItem>
      </Popover>

      <Dialog fullWidth="md" open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Stofna verkefni</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fylltu inn upplýsingarnar hér fyrir neðan
          </DialogContentText>
          <TextField
            margin="dense"
            id="heimilisfang"
            label="Heimilisfang"
            type="text"
            fullWidth
            value={heimilisfang}
            onChange={e => setHeimilisfang(e.target.value)}
          />
          <TextField
            margin="dense"
            id="vidskiptavinur"
            label="Viðskiptavinur"
            type="text"
            fullWidth
            value={vidskiptavinur}
            onChange={e => setVidskiptavinur(e.target.value)}
          />
          <TextField
            margin="dense"
            id="simanumer"
            label="Símanúmer"
            type="text"
            fullWidth
            value={simanumer}
            onChange={e => setSimanumer(e.target.value)}
          />
          <TextField
            margin="dense"
            id="athugasemdir"
            label="Athugasemdir"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={athugasemdir}
            onChange={e => setAthugasemdir(e.target.value)}
          />
          <DatePicker
            sx={{ marginTop: '10px' }}
            id="datepicker"
            label="Dagsetning"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Hætta
          </Button>
          <Button variant="contained" disabled={isLoadingButton} onClick={handleStofnaClick} color="primary">
            Stofna
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
