import { Typography } from '@mui/material';
import { useSignOut } from 'react-auth-kit';

export default function LogoutPage() {
  const signOut = useSignOut();

  signOut();

  return (
    <div>
      <Typography variant="h4" align="center">
        Útskráning...
      </Typography>
    </div>
  );
}
