import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit'
import {
  Card,
  Stack,
  Chip,
  Container,
  Typography,
  ListItemButton,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Box,
  TextField,
  Badge,
  Button,
  Alert
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

import { useParams } from 'react-router-dom';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import PDFVerkefni from './PDFExport';

import { getProjectDetails, closeProject, openProject, billProject, stopRent, toolsList, addNewTool, editComment } from '../utils/api';

export default function VerkefniViewPage() {
  const { id } = useParams();
  const [project, setProject] = useState(null); // Add state to hold the project data
  const [isLoading, setIsLoading] = useState(true); // Add state to control the loading state
  const [openModal, setOpenModal] = useState(false);
  const [editedComments, setEditedComments] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [exportProject, setExportProject] = useState(false); // Add state to control exporting the project
  const [newRental, setNewRental] = useState('');
  const [toolList, setToolList] = useState([]);

  console.log("Project: ", project);
  // const date = new Date(project.projectStartDate);

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token, id: employeeId } = auth();

  const handleOpenModal = () => {
    setOpenModal(true);
    setEditedComments(project.information); // Set the initial value of the edited comments
  };

  const handleCheckTools = () =>
    // check if all tools have isReturned set to true
    project.tools.every(tool => tool.isReturned)
    ;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCommentEdit = async () => {
    // Call your desired logic to update the comments
    // For example, you can update the project state or make an API call
    console.log('Edited comments:', editedComments);
    handleCloseModal();
    project.information = editedComments;
    const response = await editComment(editedComments, parseInt(id, 10), token, signOut);

  };

  const handleCloseProject = async () => {
    if (!handleCheckTools()) {
      setErrorMessage("Það eru ennþá tæki skráð í útleigu. Vinsamlegast skráðu tækin/tækið út fyrst.");

      return;
    }
    setIsLoading(true);
    try {
      await closeProject(id, token, signOut);
      fetchProjectDetails();
      // Add a success message
    } catch (error) {
      console.error('Error closing project:', error);
      // Add an error message
    }
    setIsLoading(false);
  };

  const handleOpenProject = async () => {
    setIsLoading(true);
    try {
      await openProject(id, token, signOut);
      fetchProjectDetails();
      // Add a success message
    } catch (error) {
      console.error('Error open project:', error);
      // Add an error message
    }
    setIsLoading(false);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setNewRental(newValue);
  };

  const onAddNewTool = async () => {
    if (newRental.name && newRental.serial) {
      const newTool = await addNewTool(newRental.name, newRental.serial, id, employeeId, project.customerId, token, signOut);
      if (newTool) {
        fetchProjectDetails();
      }
    }
  }

  const handleBilledProject = async () => {
    if (!handleCheckTools()) {
      setErrorMessage("Það eru ennþá tæki skráð í útleigu. Vinsamlegast skráðu tækin/tækið út fyrst.");

      return;
    }
    setIsLoading(true);
    try {
      await billProject(id, token, signOut);
      fetchProjectDetails();
      // Add a success message
    } catch (error) {
      console.error('Error closing project:', error);
      // Add an error message
    }
    setIsLoading(false);
  };

  const handleExportProject = async () => {
    try {
      setExportProject(true); // Set the flag to true to indicate exporting the project
    } catch (error) {
      console.error('Error closing project:', error);
      // Add an error message
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString); // Convert the date string to a Date object
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate;
  }

  const fetchProjectDetails = async () => {
    setIsLoading(true);
    try {
      const projectData = await getProjectDetails(id, token, signOut);
      setProject(projectData);
      const tools = await toolsList(token, signOut);
      setToolList(tools);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProjectDetails();
  }, [id]);

  const handleToolClick = async (item) => {
    try {
      console.log("Stop rent tool")
      await stopRent(item.id, token, signOut);
      // Add a success message
      // Then fetch project details again
      await fetchProjectDetails();
    } catch (error) {
      console.error('Error removing tool:', error);
      // Add an error message
    }
  };

  if (exportProject) {
    return <PDFVerkefni project={project} />; // Render the PDFVerkefni component with the project data
  }

  if (isLoading) {
    // Add a loading state
    return (
      <>
        <Helmet>
          <title> Skoða Verkefni | Þúsund Fjalir ehf </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Skoða verkefni
            </Typography>
          </Stack>
          <Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100%', minHeight: 300 }}
            >
              <CircularProgress color="primary" />
            </Stack>
          </Card>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Skoða Verkefni | Þúsund Fjalir ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Skoða verkefni
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} mb={2}>
          {project && project.projectStatus && (
            <Button startIcon={<LockIcon />} color="error" variant="contained" onClick={handleCloseProject}>
              Loka Verkefni
            </Button>
          )}
          {project && !project.projectStatus && (
            <Button startIcon={<PictureAsPdfIcon />} variant="contained" onClick={handleExportProject}>
              Sækja Verkefni
            </Button>
          )}
          {project && !project.isBilled && !project.projectStatus && (
            <Button startIcon={<AttachMoneyIcon />} color="error" variant="contained" onClick={handleBilledProject}>
              Merkja Rukkað
            </Button>
          )}
          {project && !project.isBilled && !project.projectStatus && (
            <Button startIcon={<LockOpenIcon />} color="success" variant="contained" onClick={handleOpenProject}>
              Opna Verkefni
            </Button>
          )}
        </Stack>

        <Card>
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <Grid container spacing={2} sx={{ padding: '24px', alignItems: 'flex-start' }}>
            <Grid item xs={12} md={12}>
              <Badge sx={{ marginTop: '10px', marginBottom: '20px' }} badgeContent={project && project.projectStatus ? 'OPIÐ' : 'LOKAÐ'} color={project && project.projectStatus ? 'success' : 'error'}>
                <Chip
                  size="large"
                  label={project.customerAddress}
                  sx={{
                    padding: '15px',
                    fontSize: '1.2rem', // Adjust the font size to make the Chip bigger
                    fontWeight: 'bold', // Set the font weight to bold for the content inside the Chip
                  }}
                />
              </Badge>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography><b>Viðskiptavinur:</b> {project.customerName}</Typography>
              <Typography><b>Sími:</b> {project.customerPhone}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography><b>Starfsmaður:</b> {project.employeeFirstName} {project.employeeLastName}</Typography>
              <Typography><b>Dagsetning:</b> {new Date(project.projectStartDate).toLocaleDateString('en-GB')}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <b>Athugasemdir</b> <Typography style={{ whiteSpace: 'pre-line' }} onClick={handleOpenModal}>{project.information}</Typography>
              <Divider sx={{ margin: "10px" }} />
            </Grid>
            <Grid item xs={7} md={7} sx={{ borderRight: "1px solid rgba(145, 158, 171, 0.24)" }}>
              <Typography variant="subtitle1">Tækjalisti</Typography>
              {project && project.tools && project.tools.map((item, index) => (
                <ListItemButton
                  key={index}
                  sx={{ width: 'fit-content' }}
                  onClick={() => handleToolClick(item)}
                >
                  <Typography variant="subtitle1">{item.name}</Typography>
                  <Typography variant="body2" sx={{ marginLeft: '10px' }}>({item.serial})</Typography>
                  <Badge sx={{ marginTop: '10px', marginBottom: '20px' }} badgeContent={item.isReturned ? 'SKILAÐ' : 'Í LEIGU'} color={item.isReturned ? 'success' : 'error'}>
                    <Chip sx={{ marginLeft: '10px' }}
                      label={`Inn: ${formatDate(item.rentStartDate)}${item.isReturned ? ` - Út: ${formatDate(item.rentEndDate)}` : ''}`}
                    />
                  </Badge>
                </ListItemButton>
              ))}
            </Grid>
            <Grid item xs={5} md={5}>
              {project && project.projectStatus && (
                <>
                  <Box sx={{ margin: "10px", display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="subtitle1">Bæta við tæki</Typography>
                  </Box>
                  <Box sx={{ margin: "10px", display: 'flex', flexDirection: 'row-reverse' }}>
                    <Autocomplete
                      id="taeki-select"
                      options={toolList}
                      sx={{ width: 300 }}
                      onChange={handleAutocompleteChange}
                      getOptionLabel={(option) => `${option.name} - ${option.serial}`}
                      renderInput={(params) => <TextField {...params} label="Tæki - Seríal Númer" />} />
                  </Box>
                  <Box sx={{ margin: "10px", display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button sx={{ marginTop: "10px" }} startIcon={<AddIcon />} color="primary" variant="outlined" onClick={onAddNewTool}>
                      Senda
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Card>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: 'none',
              outline: 'none',
              boxShadow: 24,
              p: 4,
            }}
          >
            <TextField
              label="Breyta athugasemd"
              multiline
              rows={4}
              value={editedComments}
              onChange={(event) => setEditedComments(event.target.value)}
              sx={{ marginBottom: '16px', width: '600px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button onClick={handleCommentEdit} variant="contained">Breyta</Button>
            </Box>
          </Box>
        </Modal>
      </Container >
    </>
  );
}
