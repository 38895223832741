import { useEffect } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const PDFVerkefni = ({ project }) => {
    useEffect(() => {
        createPDF();
    }, []);

    const createPDF = async () => {
        const JsPDF = jsPDF;
        const pdf = new JsPDF('portrait', 'pt', 'a4');
        pdf.html(document.querySelector('#pdf')).then(() => {
            const s = `${project.projectId}_${project.customerAddress}.pdf`
            const fileName = s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
            pdf.save(fileName);
        });
    };

    const calculateDurationInHours = (start, end) => {
        const startDateTime = new Date(start);
        const endDateTime = new Date(end);

        // If start and end dates are on the same day, return 24
        if (startDateTime.toDateString() === endDateTime.toDateString()) {
            return '24';
        }

        const durationInDays = Math.ceil((endDateTime - startDateTime) / (1000 * 60 * 60 * 24));
        const durationInHours = durationInDays * 24;

        return durationInHours.toString();
    };

    const calculateDurationInDays = (start, end) => {
        const startDateTime = new Date(start);
        const endDateTime = new Date(end);

        // If start and end dates are on the same day, return 1
        if (startDateTime.toDateString() === endDateTime.toDateString()) {
            return '1';
        }

        const durationInDays = Math.ceil((endDateTime - startDateTime) / (1000 * 60 * 60 * 24));

        return durationInDays.toString();
    };

    return (
        <div className="shipping">
            <div id="pdf" style={{ fontFamily: "Arial, sans-serif", fontSize: '0.8em', margin: '20px' }}>
                <img src="/tflogo.png" alt="TF LOGO" width="200px" />
                <h3>Tækjaleiga #{project.projectId}</h3>
                <p>Heimilisfang: {project.customerAddress}</p>
                <p>Viðskiptavinur: {project.customerName}</p>
                <p>Sími: {project.customerPhone}</p>
                <hr />
                <p>Starfsmaður: {project.employeeFirstName} {project.employeeLastName}</p>
                <hr />
                <h3>Tækjalisti</h3>
                <table cellSpacing={10}>
                    <thead>
                        <tr>
                            <th>Tegund</th>
                            <th>Undirgerð</th>
                            <th>Klst (Í heild)</th>
                            <th>Dagar (Í heild)</th>
                        </tr>
                    </thead>
                    <tbody align="center">
                        {project && project.tools && project.tools.map((tool) => (
                            <tr key={tool.tool_id}>
                                <td>{tool.name}</td>
                                <td>{tool.serial}</td>
                                <td>{calculateDurationInHours(tool.rentStartDate, tool.rentEndDate)}klst</td>
                                <td>{calculateDurationInDays(tool.rentStartDate, tool.rentEndDate)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button onClick={createPDF} type="button">
                Sækja PDF
            </button>
        </div>
    );
};

export default PDFVerkefni;
