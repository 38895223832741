import { useEffect, useState } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit'
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, IconButton, Popover } from '@mui/material';
import Gravatar from 'react-gravatar'
// mocks_
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  const auth = useAuthUser();
  const signOut = useSignOut();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    signOut();
    setOpen(null);
  };

  const handleSettings = () => {
    navigate('/dashboard/account');
    setOpen(null);
  };

  const { firstName } = auth();
  const { email } = auth();

  useEffect(() => {
    setUserEmail(email);
    setUserName(firstName);
  }, [email, firstName]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Gravatar email={userEmail} alt="photoURL" style={{ borderRadius: '50%' }} size={40} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleSettings} sx={{ m: 1 }}>
          Stillingar
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Útskrá
        </MenuItem>
      </Popover>
    </>
  );
}
