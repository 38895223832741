import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SendIcon from '@mui/icons-material/Send';

const navConfig = [
  {
    title: 'Stjórnborð',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
  },
  {
    title: 'Starfsmenn',
    path: '/dashboard/user',
    icon: <PersonIcon />,
  },
  {
    title: 'Verkefni',
    path: '/dashboard/verkefni',
    icon: <LockOpenIcon />,
  },
  {
    title: 'Lokuð Verkefni',
    path: '/dashboard/closed',
    icon: <LockIcon />,
  },
  {
    title: 'Tækjalisti',
    path: '/dashboard/taeki',
    icon: <FormatListBulletedIcon />,
  },
  {
    title: 'Tilkynningar',
    path: '/dashboard/tilkynningar',
    icon: <SendIcon />,
  },
];

export default navConfig;
